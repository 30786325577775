import React from 'react'
import { NavLink } from 'react-router-dom'
import Image1 from '../assets/Images/About1_Newv1.png'
import Image2 from '../assets/Images/About2_NewV1.png'
import Image3 from '../assets/Images/About3_NewV1.png'
import Image4 from '../assets/Images/About4_NewV1.png'
import AboutPhoneSection from '../components/landing_Phone_view/About_phoneSection';
import Slider from 'react-slick';


const slides = [
  {
    title: "India's leading platform that uses the power of technology and human expertise to deliver resolutions of your disputes",
    image: Image1,
    alt:'online dispute resolution platform'
  },
  {
    title: 'Whether it is money recovery, property, insurance, employment or any other civil dispute, we have a solution for you',
    image: Image2,
    alt:'Dispute resolution solution'
  },
  {
    title: 'Experience high resolution rates through participation across geographies and diverse socio-economic backgrounds ',
    image: Image3,
    alt:'ODR firms'
  },
  {
    title: 'Achieve enforceable outcomes in a timely manner, while drastically reducing the cost in resolving your dispute',
    image:Image4,
    alt:'online dispute resolution system'
  },
];


export default function About() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay:   true,
    autoplaySpeed: 4000,
    arrows: false,
    lazyLoad:'ondemand',
    dotsClass:'sliderDots',
    customPaging: i => (
      <div
      >
        {i + 1}
      </div>
    )
};
  return (
    <div className='section about_section_main_wrap homepage'>
    <div className='section_heading'>
        <h2 className='blue_heading'  data-aos="fade-up" data-aos-duration="1500"
     >Who we are </h2>
    </div>
    { window.innerWidth > 800 ?  <Slider {...settings}>
      {
        slides.map((ele,index)=>{
          return (
            <>
           
            <div className='about_slide_main_wrap'  data-aos="fade-right" data-aos-duration="1500">
     <div className='about_slide_content'>
      <h2 >{ele.title}</h2>  
      <NavLink exact  to="/about-us"><button className='orange_btn'>Learn More</button></NavLink>                                    
     </div>
     <div className='about_slide_img_wrap'>
      <img src={ele.image} alt={ele.alt} />
     </div>
  </div>
  </>
          )
        })
      }
    </Slider> : <AboutPhoneSection data={slides}/>}
    </div>
  )
}
