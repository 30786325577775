import React,{useState} from 'react'
import Pagination from '../../components/Pagination';
import CaseAlertImage from '../../assets/Images/case alerts images.png'
import AnimatedBox from '../../components/AnimatedBox';
import DynamicModal from '../../components/DynamicModel/DynamicModal';
import Case1 from '../../components/AllCase/Case1';
import Case2 from '../../components/AllCase/Case2';
import Case3 from '../../components/AllCase/Case3';
import Image1 from '../../assets/Images/caseAlertImages/Case1.png'
import Image2 from '../../assets/Images/caseAlertImages/Case2.png'
import Image3 from '../../assets/Images/caseAlertImages/Case3.png'
import Image4 from '../../assets/Images/caseAlertImages/Case04.png'
import Image5 from '../../assets/Images/caseAlertImages/Case05.png'
import Image6 from '../../assets/Images/caseAlertImages/Case4.png'
import Image7 from '../../assets/Images/caseAlertImages/Case5.png'
import Image8 from '../../assets/Images/caseAlertImages/Case6.png'
import Image9 from '../../assets/Images/caseAlertImages/Case7.png'
import Image10 from '../../assets/Images/caseAlertImages/Case010.png'
import Image11 from '../../assets/Images/caseAlertImages/Case9.png'
import Image12 from '../../assets/Images/caseAlertImages/Case10.png'
import Image13 from '../../assets/Images/caseAlertImages/Case11.png'

import Image14 from '../../assets/Images/caseAlertImages/Case12.png'
import Image15 from '../../assets/Images/caseAlertImages/Case15.png'
import Image16 from '../../assets/Images/caseAlertImages/Case16.png'
import Image17 from '../../assets/Images/caseAlertImages/Case17.png'
import Image18 from '../../assets/Images/caseAlertImages/Case18.png'
import Image19 from '../../assets/Images/caseAlertImages/Case19.png'
import Case4 from '../../components/AllCase/Case4';
import Case5 from '../../components/AllCase/Case5';
import Case6 from '../../components/AllCase/Case6';
import Case7 from '../../components/AllCase/Case7';
import Case8 from '../../components/AllCase/Case8';
import Case9 from '../../components/AllCase/Case9';
import Case10 from '../../components/AllCase/Case10';
import Case11 from '../../components/AllCase/Case11';
import Case12 from '../../components/AllCase/Case12';
import Case13 from '../../components/AllCase/Case13';
  import {Case14,Case15,Case16,Case17,Case18,Case19} from '../../components/AllCase/Case15_19';

const CaseData = [
  {
    tittle:"Arbitration Clause in Hyperlinked Terms and Conditions Incorporated by Reference: Delhi High Court",
    description:"In this case, the Hon’ble Delhi High Court dealt with cross-appeals arising from an order of a Commercial Court rejecting an application under Section 8 of the Arbitration and Conciliation Act, 1996 filed to refer parties to arbitration in a suit.",
    Link:"https://www.livelaw.in/high-court/delhi-high-court/delhi-high-court-arbitration-clause-terms-conditions-hyperlink-website-agreement-256420"
    ,      image:Image19,
    caseNumber:19,
    },
    {
      tittle:"Challenge to Arbitral Award passed by Arbitrator appointed by Presolv360 Rejected by Delhi Court",
      description:"Challenge to arbitral award passed by arbitrator appointed by Presolv360 rejected by Delhi Commercial Court. In a significant ruling, the Delhi Commercial Court dismissed objections filed under Section 34 of the Arbitration and Conciliation Act, 1996 challenging an arbitral award on grounds of alleged unilateral appointment of the arbitrator and lack of proper notice to the petitioner.",
      Link:"https://drive.google.com/file/d/1XkLglQBmCpZSlDKk0gcV2Yf4OPYMbB9j/view?usp=sharing"
      ,      image:Image18,
      caseNumber:18,
      },
      {
        tittle:"Delhi High Court Upholds Unilateral Arbitrator Appointment, Deems Post-Award Challenge Untenable",
        description:"The Hon’ble Delhi High Court highlighted the importance of following proper procedures when objecting to the appointment of an arbitrator under an arbitration agreement. The court upheld the rejection of a challenge under Section 34 of the Arbitration and Conciliation Act, 1996, against an arbitral award, where the arbitrator was appointed unilaterally as per the arbitration clause.",
        Link:"https://drive.google.com/file/d/1n6bGHV0k-a1U6BmP-_-R1_lC9yCqb5ti/view"
        ,      image:Image17,
        caseNumber:17,
        },
        {
          tittle:"Delhi High Court Appoints Arbitrator Based on Email and WhatsApp Service",
          description:"The Hon’ble Delhi High Court allowed a petition filed under Section 11 of the Arbitration and Conciliation Act, 1996, basis successful service of communications on email and WhatsApp.",
          Link:"https://www.livelaw.in/high-court/delhi-high-court/delhi-high-court-service-whatsapp-email-address-valid-255054"
          ,      image:Image16,
          caseNumber:16,
          },
          {
            tittle:"Providing Wrong Address Bars Objection to Arbitration Notice",
            description:"In a notable decision, the Hon’ble Delhi High Court addressed a jurisdictional issue involving the appointment of an arbitrator in a dispute arising from a partnership deed.",
            Link:"https://www.livelaw.in/arbitration-cases/party-providing-wrong-address-during-proceedings-cannot-argue-incorrect-arbitration-notice-us-21-ac-delhi-high-court-250572?from-login=118249"
            ,      image:Image15,
            caseNumber:15,
            },

            {
              tittle:"ODR-administered Arbitration finds Acceptance in Execution Proceedings Despite Unilateral Arbitrator Appointment Clause in the Agreement",
              description:"The Commercial Court in Saket, New Delhi, on 5th January 2024, dealt with an execution petition based on an arbitration award in a dispute administered by Presolv360 ODR Institution. The pivotal issue was the unilateral arbitrator appointment clause in the agreement",
              Link:"https://drive.google.com/file/d/1ZOCqYK7K4mBnu3oUPc2HqSymLvNzOshG/view"
              ,      image:Image14,
              caseNumber:14,
              }
               ,
               {
                tittle:"Institutional Arbitration through Online Dispute Resolution (ODR) Platform",
                description:"Supreme Court dismisses a Special Leave Petition upholding Delhi High Court's decision against executing an arbitration award by a unilaterally appointed arbitrator, aligning with past rulings deeming such appointments void. Presolv360 advocates a tech-driven institutional arbitration solution, supported by a Commercial Court in Bengaluru in a case where an arbitrator was appointed via an ODR platform.",
                Link:"https://drive.google.com/file/d/1OLGGFlYQP7yKeUOId7lbHRd5-9R1e3cM/view"
                ,      image:Image13,
                caseNumber:13,
                },
                {
                  tittle:"Bombay High Court Addresses Jurisdictional Challenge in Arbitration Involving a Financial Institution",
                  description:"The Hon'ble Bombay High Court has addressed a significant jurisdictional challenge in arbitration proceedings involving a financial institution. The Court ruled against the contention that as a 'financial institution' under the SARFAESI Act, the petitioner is restricted to proceed only under the SARFAESI Act and barred from invoking arbitration. ",
                  Link:"https://drive.google.com/file/d/1OCp9D31b46g4D3Bew48ZSRv-AmNvu17Q/view"
                  ,      image:Image12,
                  caseNumber:12,
                  } , 
                  {
                    tittle:"Kerala High Court Rules on Strict Adherence to Limitation Period in Arbitration Cases as per Arbitration and Conciliation Act, 1996",
                    description:"Kerala High Court rules: Section 5 of the Limitation Act doesn't apply to Section 34 applications under the Arbitration Act. Emphasis on strict adherence to the four-month limitation period for challenging arbitral awards.",
                    Link:"https://www.livelaw.in/high-court/kerala-high-court/kerala-high-court-section-5-limitation-act-arbitration-act-specified-period-limitation-245076?infinitescroll=1"
                    ,      image:Image11,
                    caseNumber:11,
                    } ,                
                    {
                      tittle:"In Re Interplay Between Arbitration Agreements Under The Arbitration And Conciliation Act 1996 And The Indian Stamp Act 1899",
                      description:"Supreme Court's seven-judge bench overturns prior ruling in M/s. N.N. Global Mercantile case, holding through a curative petition that arbitration clauses in unstamped or inadequately stamped agreements are now enforceable. A notable shift from the previous stance deeming such agreements unenforceable.",
                      Link:"https://www.livelaw.in/top-stories/arbitration-clauses-in-unstamped-agreements-enforceable-supreme-court-7-judge-bench-overruled-nn-global-decision-244387?infinitescroll=1"
                      ,      image:Image10,
                      caseNumber:10,
                      },   
                      {
                      tittle:"Delhi High Court Upholds Coexistence of Arbitration and Section 138 Proceedings in Dishonoured Cheque Case",
                      description:"Delhi High Court affirms simultaneous pursuit of arbitration and Section 138 proceedings for dishonored cheques, acknowledging their distinct causes of action. Recognition of the parallel nature of criminal and civil proceedings marks a significant legal stride.",
                      Link:"https://www.livelaw.in/pdf_upload/negotiable-instruments-506816.pdf"
                      , image:Image9,
                      caseNumber:9,
                      },
                      {
                        tittle:"Delhi High Court Rules Arbitral Tribunal cannot create Security on Already Charged  Property Dishonoured Cheque Case",
                        description:"The Delhi High Court has held that an arbitral tribunal does not have the jurisdiction to create security on a property that already has a charge in favor of a third party. This ruling emphasizes the importance of existing charges and the limitations of arbitral tribunals in modifying rights over secured properties.",
                        Link:"https://www.livelaw.in/high-court/delhi-high-court/arbitral-tribunal-has-no-jurisdiction-to-create-security-over-a-property-which-has-a-third-party-charge-delhi-high-court-243229"
                        ,image:Image8,
                        caseNumber:8,
                        },


                        {
                          tittle:"Bombay High Court Addresses Issues of Non-Stamping in Arbitration Agreements",
                          description:"Bombay High Court: Timely objections on non-stamping of arbitration agreements crucial—raise them before the arbitrator, not in later Section 34 or Section 37 proceedings under the Arbitration and Conciliation Act, 1996.",
                          Link:"https://www.livelaw.in/high-court/bombay-high-court/bombay-high-court-arbitral-award-challenged-appeal-new-grounds-241706?infinitescroll=1",
                          image:Image7,
                          caseNumber:7,
                          },

{
  tittle:"Insufficient Stamping of Arbitration Agreement Not Sole Ground for Setting Aside  Award: Delhi High Court",
  description:"The Delhi High Court rules that an arbitration award can't be dismissed solely due to insufficient stamping of the underlying agreement. Emphasizing its role as not an appellate court, the decision solidifies the autonomy of Arbitral Tribunals under Section 34 of the Arbitration and Conciliation Act, 1996.",
  Link:"https://www.livelaw.in/high-court/delhi-high-court/delhi-high-court-insufficiently-stamped-agreement-is-only-against-stamp-act-cant-be-a-ground-to-set-aside-award-233893",
  image:Image6,
  caseNumber:6,
  
  },



  {
    tittle:"Calcutta High Court Assessing the Parameters for Arbitrator’s Disqualification",
    description:"Bombay High Court: Timely objections on non-stamping of arbitration agreements crucial—raise them before the arbitrator, not in later Section 34 or Section 37 proceedings under the Arbitration and Conciliation Act, 1996.",
    Link:"https://www.livelaw.in/high-court/bombay-high-court/bombay-high-court-arbitral-award-challenged-appeal-new-grounds-241706?infinitescroll=1",
    image:Image5,
    caseNumber:5,
    },

{
  tittle:"Appointment of Arbitrator by Presolv360 held to be valid",
  description:"The Delhi High Court rules that an arbitration award can't be dismissed solely due to insufficient stamping of the underlying agreement. Emphasizing its role as not an appellate court, the decision solidifies the autonomy of Arbitral Tribunals under Section 34 of the Arbitration and Conciliation Act, 1996.",
  Link:"https://www.livelaw.in/high-court/delhi-high-court/delhi-high-court-insufficiently-stamped-agreement-is-only-against-stamp-act-cant-be-a-ground-to-set-aside-award-233893",
  image:Image4,
  caseNumber:4,
  
  },
{
  tittle:"Supreme Court of India Refers Issue of Enforceability of Unstamped Arbitration Agreements to Seven-Judge Bench",
  description:"The Supreme Court of India, led by Chief Justice DY Chandrachud, has referred the enforceability of unstamped or insufficiently stamped arbitration agreements to a seven-judge bench due to significant legal uncertainty arising from a previous ruling in the case of Bhaskar Raju and Brothers v. Dharmaratnakara Rai Bahadur Arcot Narainswamy Mudaliar Chattram.",
  Link:"https://www.livelaw.in/top-stories/arbitration-clause-in-unstamped-agreements-enforceable-supreme-court-refers-nn-global-to-seven-judge-bench-238706?infinitescroll=1",
  image:Image3,
  caseNumber:3,
  
},  


   {
    tittle:"Karnataka High Court: Penalties Under Stamp Act Not Applicable to Insufficiently Stamped Awards at Execution Stage",
    description:`"The Karnataka High Court recently ruled that penalties under the Karnataka Stamp Act, 1957 do not apply to inadequately stamped arbitral awards during their "enforcement or execution" phase. The court clarified that the imposition of penalties for insufficient stamping is inapplicable to arbitral awards in execution proceedings."`,
    Link:"https://www.barandbench.com/news/penalty-insufficiently-stamping-arbitral-award-execution-karnataka-high-cou",
    image:Image2,
    caseNumber:2,
    
},

  {
    tittle:"Delhi High Court Rules Email Delivery of Arbitral Awards Valid Under Arbitration Act",
    description:"The Delhi High Court recently stated  that sending a scanned, signed copy of an arbitral award via email constitutes a valid delivery under Section 31(5) of the Arbitration and Conciliation Act, 1996. Emphasizing the need for legal adaptation to evolving technology, the court recognized electronic delivery as a legitimate method in arbitration proceedings.",
    Link:"https://www.livelaw.in/pdf_upload/judgement-41-1-489350.pdf",
    image:Image1,
    caseNumber:1,
    
  },


   
     
        
      
]

  const AlllCaseChild = ({caseNumber}) =>{
        console.log(caseNumber)
    return (
      <div className='caseModal_wrap'>
      <div className='alertImgContainer' style={{width:"50%",margin: "auto"}}>
                 <img className='thumbnail' src={CaseData[CaseData.length-caseNumber].image} style={{width:"100%"}} alt='caseAlertImage'/>
                 </div>
         {
            (()=>{
              switch(caseNumber) {
                case 1:
                  return <Case1 />
                case 2:
                  return <Case2/>
                case 3:
                  return <Case3 />
                  case 4:
                    return <Case4 />
                  case 5:
                    return <Case5/>
                  case 6:
                    return <Case6 />
                    case 7:
                      return <Case7 />
                    case 8:
                      return <Case8/>
                    case 9:
                      return <Case9 />
                      case 10:
                        return <Case10 />
                      case 11:
                        return <Case11/>
                      case 12:
                        return <Case12 />
                        case 13:
                          return <Case13 />
                          case 14:
                            return <Case14/>
                          case 15:
                            return <Case15 />
                            case 16:
                              return <Case16 />
                            case 17:
                              return <Case17/>
                            case 18:
                              return <Case18 />
                              case 19:
                                return <Case19 />
                default:
                  return null
              }
               })
            () }
            <a className='orange_btn' rel="noreferrer" target='_blank' href={CaseData[caseNumber -1].Link}  style={{minWidth:"max-content",margin:"auto"}}>Click here for more details</a>
       </div>

    )
  }

export default function CaseAlert() {
    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage] = useState(5);
     const [showModel,setShwoModel] = useState(false)
    const [ModalNumber,setModel] = useState(1)
    function showMoreDetail (modelnumber){
      setModel(modelnumber)
     setShwoModel(true)
   }
   function ExitDetail (value) {
      setShwoModel(value)
   }

    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const currentRecords = CaseData.reverse().slice(indexOfFirstRecord, indexOfLastRecord);
    const nPages = Math.ceil(CaseData.length / recordsPerPage)
  return (

    <div className='caseAlertPage'>
        <div className='firstBreak'>
  <section className='landing_wrap'>

{
   window.innerWidth > 600 ?  <h1>
   Bringing you the latest updates in the space of arbitration and online dispute resolution straight out of court
   </h1> : <h1 style={{padding:".5em"}}>
   Bringing you the latest updates in the space of arbitration and online dispute resolution straight out of court
   </h1>
}        
        <AnimatedBox/>
    </section>
  </div>
   <section className='poadcast_main_wrap'>
   <DynamicModal isOpen={showModel} closeMOdel={ExitDetail} children={<AlllCaseChild caseNumber={ModalNumber}/>} />
{
    currentRecords.map((poadcast,index)=>{
        return (
            <div className='resource_wrap'>
            <div className='course_right_wrap'>
                <div className='alertImgContainer'>
                <img className='thumbnail' src={CaseAlertImage} style={{width:"100%"}} alt='caseAlertimage'/>
                <h5 className='alertTittle'>{poadcast.tittle}</h5>
                </div>
           
           </div>
           <div className='resource_left_wrap'>
    <p style={{lineHeight:"140%",letterSpacing:"1px",textAlign:"justify"}} >{poadcast.description}
    </p>
    <div className='resourse_social_media_wrap'>
        <div onClick={()=>{showMoreDetail(poadcast.caseNumber)}}  className='orange_btn'>View</div>
    </div>
           </div>
            </div>
        )
    })
}
<Pagination
    nPages={nPages}
    currentPage={currentPage}
    setCurrentPage={setCurrentPage}
/>
</section>
    </div>
   
  )
}
