import React from 'react'
import { Helmet } from 'react-helmet'
import AnimatedBox from '../../components/AnimatedBox'
import RulesImage from '../../assets/Images/Cookies/image 3.png'
import './Extra.css'
export default function RulesCode() {
  return (
    <div>
    <Helmet>
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no"/>
<meta name="msapplication-TileImage" content="%PUBLIC_URL%/Thumbnail2_1280_720.png" />
<title>Presolv360 | Rules, Code & Fees | ADR | Online Arbitration | Online Negotiation</title>
  <meta name="description" content="Learn from global leaders how online dispute resolution can transform and empower your disputes with expert resolution in a digital era." />
  <meta name="keywords" content="dispute resolution global" />

<meta property="og:keywords" content="ODR, ADR, Online Dispute Resolution, Alternative Dispute Resolution, Out of Court Settlement, Arbitration, Mediation, Conciliation, Online Arbitration, Online Negotiation, Money Recovery, Legaltech, ODR India" />
<meta property="og:description" content="Online Dispute Resolution platform to conduct arbitration, mediation, conciliation digitally." />
<meta property="og:image" content="%PUBLIC_URL%/Thumbnail2_1280_720.png" />
<meta property="og:image:secure_url" content="%PUBLIC_URL%/Thumbnail2_1280_720.png" />
<meta property="og:url" content="https://www.presolv360.com"/>
<meta property="og:title" content=" Presolv360 | Dispute resolution made easy" />
<meta property="og:type" content="article" />
    </Helmet>
    <div className='firstBreak extraPage'>
    <section className='landing_wrap'>
      {
        window.innerWidth > 600 ?<h1 style={{padding:"0 1.5em"}}>
      Rules, Code & Fees
        </h1> : <h1>
        Rules, Code & Fees
        </h1>
      }
          <AnimatedBox/>
      </section>
    </div>
   <div className='extra_section_wrap'>
    <h3>Dispute Resolution Rules</h3>
    <p>If you desire to resolve your dispute using Presolv360’s platform, you agree to be bound by its Dispute Resolution Rules (“Rules”).
</p>
    <p>The Rules are designed to help you take maximum advantage of Presolv360’s online dispute resolution (“ODR”) system and prevent needless litigation, safeguard yourself from adversities of disputes and resolve them quickly, economically, effectively and efficiently.
</p>
    <p>Section 1 deals with preliminary aspects such as definitions, scope, modalities pertaining to communication, party obligations in respect of platform use, and data. Section 2 covers rules for arbitration proceedings on Presolv360’s ODR platform. Section 3 encapsulates rules for mediation / conciliation proceedings on Presolv360’s ODR platform.
</p>
    <p>Note: If a dispute is referred for resolution to Presolv360 by a court, statutory authority, or governing body, then such disputes shall be resolved in accordance with the rules, regulations, and guidelines of the referring authority that are in effect as of the date on which request is made to Presolv360.
</p>
<h3>Arbitrators’ and Mediators’ Code of Conduct and Disclosure Rules</h3>
   <p>The Arbitrators’ and Mediators’ Code of Conduct and Disclosure Rules (“Code”) set out the general framework for ethics with which the arbitrators and mediators / conciliators empaneled with Presolv360 shall conduct the arbitration and mediation / conciliation proceedings, respectively. The Code also governs the disclosure requirements to act as an arbitrator or a mediator / conciliator.
</p>
   <p>While Section 1 deals with preliminary aspects such as definitions, scope, modalities pertaining to communication, and arbitrators’ and mediators’ / conciliators’ obligations in respect of platform use, Section 2 and 3 govern the code of conduct and disclosure rules for arbitrators and mediators / conciliators empanelled with Presolv360, respectively.
</p>
   <p><a href="https://presolv360.com/login" target="_blank" rel="noopener noreferrer">Click here</a> to access the governing documents for resolving disputes on Presolv360’s ODR platform.</p>
   <h3>Model Fee Schedule</h3>
   <p>If a dispute is referred for resolution to Presolv360 by a court, statutory authority, or governing body, then the fee for resolution of such disputes shall be in accordance with the fee schedule prescribed by the referring authority.
</p>
<p>Model fee per dispute for arbitration administered by Presolv360 on its ODR platform is as under :</p>
<img src={RulesImage} alt='Model Fee Schedule' style={{width:"80%",minWidth:"280px"}}/>
<p>For claim amounts over Rs. 1,00,00,000, kindly reach out to us at <a href="mailto:info@presolv360.com">info@presolv360.com</a> .
</p>
<p>Model fee per dispute for mediation / conciliation administered by Presolv360 on its ODR platform is as under:
</p>
<p>Registration fee – Rs. 4,500;<br></br>
Administrative fee per session – Rs. 5,000;<br></br>
Mediator / Conciliator fee – As agreed between the mediator / conciliator and the parties.
</p>
 <p>Note :
</p>
<p>(a) The aforesaid fees are non-refundable and exclusive of applicable taxes.
</p>
<p>(b) The fees are payable irrespective of the outcome of the dispute, promptly upon receipt of a payment requisition by Presolv360.
</p>
<p>(c) Stamp duty, statutory charges, out-of-pocket expenses, if any, shall be reimbursed to Presolv360 in accordance with the fee imposed by law or on actual basis, as the case may be.
</p>
<p>(d) The cost or professional charges of any legal practitioner or authorised representative, including without limitation, cost of appointment and / or representation and / or any other incidental costs shall be borne by the respective parties.
</p>
<p>(e) Each party shall be responsible for its respective taxation obligations as per the applicable laws for the time being in force .</p>

  <h3>Project ‘Presolv for All’</h3> 
  <p>Under this project, Presolv360 extends its services free of cost to persons from low-income groups. Based on the eligibility and facts of the dispute, applications will be screened, and eligible parties will be notified.
</p>
<h3>Project ‘Hear the Difference’</h3>
<p>With this project, Presolv360 aims to achieve the objective of making dispute resolution accessible to persons with disabilities. Communications sent via the platform will provide an option to solicit an Indian Sign Language interpreter to help people with hearing disabilities understand and communicate.
</p>
<p>If you have any questions or require any clarifications, please feel free to reach out to us at <a href="mailto:info@presolv360.com">info@presolv360.com</a></p>
   </div>

    </div>
  )
}
