export  function Case14() {
    return (
      <>
     <div>
     <i>Case Title:  ODR-administered Arbitration finds Acceptance in Execution Proceedings
Despite Unilateral Arbitrator Appointment Clause in the Agreement
     </i>
     <i>
     Court: Commercial Court, New Delhi
     </i>
     <i>Date of Judgement:  05.01.2024</i>
     </div>
      <p style={{paddingTop:"1em"}} >
      The Commercial Court in Saket, New Delhi, on 5th January 2024, dealt with an execution petition based
on an arbitration award in a dispute administered by Presolv360 ODR Institution. The pivotal issue was
the unilateral arbitrator appointment clause in the agreement. The Court was intimated that the
appointment of an arbitrator was not unilateral. Notice had been issued to the opposite party for the
appointment of an arbitrator from a neutral institution i.e. Presolv360, which is a recognized
independent ODR platform, and no objection was raised. The Court stated that the appointment of the
Arbitrator was not unilateral and issued notice to the judgment debtor in the execution proceedings  
  </p>  

     </>
    )
  }
  export  function Case15() {
    return (
      <>
      <b  style={{textAlign:"center"}}>Providing Wrong Address Bars Objection to Arbitration Notice: Delhi High Court 
     </b>
     <div>
     <i>Case Title: Devender Kumar Kashyap vs Chander Muni
     </i>
     <i>
     Court: Delhi High Court
     </i>
     <i>Date of Judgement: 04.12.2023
</i>
     </div>
      <p style={{paddingTop:"1em"}} >
      In a notable decision, the Hon’ble Delhi High Court addressed a jurisdictional issue involving the
appointment of an arbitrator in a dispute arising from a partnership deed. The respondent objected to
the petitioner's application under Section 11 of the Arbitration and Conciliation Act, 1996, (“Act”)
contending that the invocation notice under Section 21 of the Act was not served at the correct address,
rendering the petition premature  
  </p>  
  <p style={{paddingTop:"1em"}}>
  The Hon’ble High Court rejected the respondent’s objection, observing that the respondent had himself provided the same address in a separate proceeding. The court clarified that the purpose of an invocation notice is to inform the opposite party about the claimant’s intent to invoke arbitration, and since the respondent was aware of the petitioner’s intention to pursue arbitration since 2021, the petition could not be deemed premature.
  
  </p>
  <p style={{paddingTop:"1em"}}>
  Distinguishing a cited case where the respondent was unaware of the invocation notice due to an incorrect address, the Hon’ble High Court upheld the validity of the invocation notice and appointed an arbitrator to adjudicate the disputes arising from the partnership deed.
  </p>
     
    
   
     {/* <p style={{paddingBottom:"1.5em"}}>
     The case involved a dishonoured cheque, initially part of an arbitration agreement in a Memorandum of Understanding (MoU) between the parties. The court determined that the nature of the cheque (whether it was security or not) is a matter for trial, thereby allowing the Section 138 complaint to proceed despite the arbitration.
     </p> */}
     </>
    )
  }
  export  function Case16() {
    return (
      <>
      <b  style={{textAlign:"center"}}> Delhi High Court allows Section 11 Petition Based on Email and WhatsApp Service
     </b>
     <div>
     <i>Case Title: M/S Lease Plan India Private Limited vs M/S Rudraksh Pharma Distributor & Ors.
     </i>
     <i>
     Court: Delhi High Court
     </i>
     <i>Date of Judgement: 10.04.2024</i>
     </div>
      <p style={{paddingTop:"1em"}} >
      The Hon’ble Delhi High Court allowed a petition filed under Section 11 of the Arbitration and
Conciliation Act, 1996 (“A&C Act”), referring the dispute to the Delhi International Arbitration Centre
(“DIAC”) to appoint the arbitrator and adjudicate disputes between the parties. This decision came after
the respondents failed to appear despite being served through email and WhatsApp as per the contact
details mentioned in the underlying Lease Agreement.  
  </p>  
  <p style={{paddingTop:"1em"}}>
  The petitioner had invoked arbitration by a notice dated 31.12.2022, proposing three names as
arbitrators. However, the respondents did not respond. The Court noted that the respondents were duly
served on 20.02.2024 via email and WhatsApp on the email and phone numbers provided in the
agreement, as evidenced by an affidavit filed by the petitioner’s counsel. Despite attempts at postal
service being unsuccessful, the Court held the email and WhatsApp service sufficient to proceed.  
  </p>
  <p style={{paddingTop:"1em"}}>
  Satisfied with the existence of an arbitration agreement, the Court referred the disputes to DIAC,
directing it to nominate an arbitrator from its panel.  </p>
    
     </>
    )
  }
  export  function Case17() {
    return (
      <>
      <b  style={{textAlign:"center"}}>Providing Wrong Address Bars Objection to Arbitration Notice  
     </b>
     <div>
     <i>Case Title:  Arjun Mall Retail Holdings (P) Ltd. v. Gunocen Inc
     </i>
     <i>
     Court: Delhi High Court
     </i>
     <i>Date of Judgement: 23.01.2024</i>
     </div>
      <p style={{paddingTop:"1em"}} >
      The Hon’ble Delhi High Court highlighted the importance of following proper procedures when
objecting to the appointment of an arbitrator under an arbitration agreement. The court upheld the
rejection of a challenge under Section 34 of the Arbitration and Conciliation Act, 1996 (“Act”) against
an arbitral award, where the arbitrator was appointed unilaterally as per the arbitration clause  
  </p>  
  <p style={{paddingTop:"1em"}}>
  While the court did not expressly rule on the validity of such unilateral appointments, it emphasized
that merely raising informal objections, such as through a letter to the arbitrator citing lack of consent,
is insufficient. The appellant in this case had failed to legally challenge the appointment or the validity
of the arbitration clause itself through the appropriate mechanism under Section 11(6) of the Act during
the time gap before commencement of the arbitration.  
  </p>
  <p style={{paddingTop:"1em"}}>
  The Hon’ble Court termed the conduct of the aggrieved party as that of a “mute spectator”. It was noted
that by not participating in the subsequent arbitration proceedings despite having knowledge of the
proceedings, after the initial objections raised by a letter were overruled, the aggrieved party must be
deemed to have waived their right to later challenge the appointment.   </p>
     
<p style={{paddingTop:"1em"}}>
The Hon’ble Court emphasised that under Section 34, the scope to interfere with an arbitral award is
limited only to grounds raised during the arbitral proceedings.
</p>
   
     {/* <p style={{paddingBottom:"1.5em"}}>
     The case involved a dishonoured cheque, initially part of an arbitration agreement in a Memorandum of Understanding (MoU) between the parties. The court determined that the nature of the cheque (whether it was security or not) is a matter for trial, thereby allowing the Section 138 complaint to proceed despite the arbitration.
     </p> */}
     </>
    )
  }
  export  function Case18() {
    return (
      <>
      <b  style={{textAlign:"center"}}>Challenge to Arbitral Award passed by Arbitrator appointed by Presolv360 Rejected by Delhi
Court  
     </b>
     <div>
     <i>Case Title:  Nalini Gupta Vs. UGRO Capital Limited
     </i>
     <i>
     Court: Delhi Commercial Court
     </i>
     <i>Date of Judgement:  19.04.2024</i>
     </div>
      <p style={{paddingTop:"1em"}} >
      In a significant ruling, a Delhi Commercial Court dismissed objections filed under Section 34 of the
Arbitration and Conciliation Act, 1996 (“Act”) challenging an arbitral award on grounds of alleged
unilateral appointment of the arbitrator and lack of proper notice to the petitioner  
  </p>  
  <p style={{paddingTop:"1em"}}>
  The petitioner claimed that the arbitrator was appointed unilaterally by the respondent and that the
petitioner did not receive any notice seeking its consent for the appointment. However, the court found
that the respondent did not appoint the arbitrator but rather approached Presolv360, an ODR institution
recognized by the Ministry of Law and Justice, which selected the arbitrator from its panel, and hence
the appointment was not unilateral.  
  </p>
  <p style={{paddingTop:"1em"}}>
  The court in this regard stated that “I am of the considered opinion that it is not the respondent who
chose the arbitrator for conducting the arbitral proceedings. The respondent approached an ODR
institution Presolv360 and this institution selected one arbitrator from its panel. The arbitrator duly
gave declaration u/s 12 of the Act as required and her appointment by the institution is not hit by Section
12 (5) of the Arbitration and Conciliation Act.”  </p>
     
     <p style={{paddingTop:"1em"}}>
     The petitioner furthermore contended that she did not receive notice of the arbitrator’s appointment or
notices under Section 21 of the Act. However, the court observed that notices were sent to the email
address and mobile number provided by the objector in the KYC form, establishing valid service. The
court highlighted that while postal notices were returned with remarks like “left without instructions,”
the petitioner received the copy of the award at the same address, indicating an attempt to avoid the
arbitral proceedings.
     </p>
     <p style={{paddingTop:"1em"}}>
     The court emphasized that the petitioner had a valid arbitration agreement, received proper notice of the
arbitrator’s appointment, and the award was passed by a neutral person. Consequently, the objections
under Section 34 were dismissed.
</p>
<p style={{paddingTop:"1em"}}>
This ruling underscores the court’s stance against allowing objections based on unilateral appointment
allegations when the arbitrator is appointed by an institutional mechanism. It also highlights the
importance of participating in arbitral proceedings and not engaging in attempts to manipulate service
records.
</p>
     </>
    )
  }
  export  function Case19() {
    return (
      <>
      <b  style={{textAlign:"center"}}>Arbitration Clause in Hyperlinked Terms and Conditions Incorporated by Reference: Delhi
High Court
 
     </b>
     <div>
     <i>Case Title:  : M/s Oravel Stays Pvt Ltd v. Nikhil Bhalla1
     </i>
     <i>
     Court: Delhi High Court
     </i>
     <i>Date of Judgement:  23.04.2024</i>
     </div>
      <p style={{paddingTop:"1em"}} >
      In this case, the Hon’ble Delhi High Court dealt with cross-appeals arising from an order of a
Commercial Court rejecting an application under Section 8 of the Arbitration and Conciliation Act, 1996
(the “Act”) filed to refer parties to arbitration in a suit.
  </p>  
  <p style={{paddingTop:"1em"}}>
  The key issue was whether the arbitration clause in the Terms and Conditions published on a website
was incorporated into the Marketing and Operational Consulting Agreement (“MOCA”) between the
parties by reference under a particular clause of the MOCA.
  </p>
  <p style={{paddingTop:"1em"}}>
  The Hon’ble Court held that the MOCA expressly incorporated the Terms and Conditions published on
the website as part of the MOCA. It provided a hyperlink to access the Terms and Conditions, which
included an arbitration clause.  </p>

<p style={{paddingTop:"1em"}}>
The Court relied on Section 7(5) of the Act, which states that reference in a contract to a document
containing an arbitration clause constitutes an arbitration agreement if the reference is such as to make
that clause part of the contract.
</p>
<p style={{paddingTop:"1em"}}>
The Court rejected arguments that there was no specific reference to the arbitration clause, and that the
hyperlink did not directly lead to the relevant Terms and Conditions. It held that the fact that the website
had different terms for different categories of parties did not negate the incorporation by reference.
</p>
     
    
   
     {/* <p style={{paddingBottom:"1.5em"}}>
     The case involved a dishonoured cheque, initially part of an arbitration agreement in a Memorandum of Understanding (MoU) between the parties. The court determined that the nature of the cheque (whether it was security or not) is a matter for trial, thereby allowing the Section 138 complaint to proceed despite the arbitration.
     </p> */}
     </>
    )
  }