import React from 'react'
import Landing from '../Section/landing'
import Stats from '../Section/Stats'
import OfferingSection from '../Section/OfferingSection'
import Client from '../Section/Client'
import { useEffect } from 'react'
import GuidedFinest from '../Section/GuidedFinest'
import Approach from '../Section/Approach'
import Outcome from '../Section/Outcome'
import Testimonal from '../Section/Testimonal'
import About from '../Section/About'
import Course from '../Section/Course'
import Resource from '../Section/Resource'
import Partnership from '../Section/Partnership'
import { Helmet } from 'react-helmet'


export default function Home() {
  function showButton(){
  
    const btn = document.getElementById('nav_enq_btn')
    if(btn !== null) {
      const element =   document.getElementById('nav')

      if(window.scrollY > 0.7*window.innerHeight){
            btn.style.visibility ="visible"
            element.style.backgroundColor="#fff" 
            element.style.backgroundImage="none"
      }
       else {
        btn.style.visibility ="hidden"
        element.style.backgroundColor="none" 
        element.style.backgroundImage="var(--gradient_url)"
       }
    }
   
}
   useEffect(()=>{
    window.addEventListener('scroll',showButton)
   },[])
  return (
    <div>
      <Helmet>
      <title> Online Dispute Resolution | ODR in India | Presolv360</title>
      <link rel="icon" type="image/x-icon" href="%PUBLIC_URL%/favicon.ico" />
      <link rel="icon" type="image/x-icon" href="../../public/favicon.ico" />
      <link rel="icon" type="image/png" href="../../public/logo512.png" />
      <link rel="canonical" href="https://presolv360.com/" />
<meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no"/>
<meta name="msapplication-TileImage" content="%PUBLIC_URL%/Thumbnail2_1280_720.png" />
<meta property="og:image" content="%PUBLIC_URL%/Thumbnail2_1280_720.png" />
<meta property="og:image:secure_url" content="%PUBLIC_URL%/Thumbnail2_1280_720.png" />
<meta name="description" content="Presolv360 helps Settle disagreements for businesses & individuals resolve disputes efficiently. Reduce costs & save time." />
<meta name="keywords" content="odr in india, ODR platform, mediation in india, arbitration in India, commercial litigation, alternative dispute resolution mechanism, judicial settlement in adr, alternative dispute resolution methods" />
<meta property="og:keywords" content="ODR, ADR, Online Dispute Resolution, Alternative Dispute Resolution, Out of Court Settlement, Arbitration, Mediation, Conciliation, Online Arbitration, Online Negotiation, Money Recovery, Legaltech, ODR India" />
<meta property="og:description" content="Online Dispute Resolution platform to conduct arbitration, mediation, conciliation digitally." />

      </Helmet>
      <div className='firstBreak'>
      <Landing/>
     <About/>
      </div>
  
    <Stats/>
    <div className='secondBreak'>
    <OfferingSection/>
    <GuidedFinest/>
    </div>

    <Approach/>
    <Outcome/>
    <Client/>
    <Testimonal/>
    <Partnership/>
    <div className='secondBreak'>
    <Course/>
    <Resource/>
    </div>
    </div>
   
  )
}
